<template>
  <div>
    <v-dialog max-width="550" v-model="share_recording_dialog">
      <v-card>
        <v-toolbar class="primary white--text" flat>
          <!-- <span class="font-weight-bold text-left">Edit Recording</span> -->
          <v-tabs v-model="tab" class="share-tab-toolbar" fixed>
            <v-tab class="share-tab-item" @click="reloadComponent(1)"
              >Share</v-tab
            >
            <v-tab class="share-tab-item" @click="reloadComponent(2)"
              >Social</v-tab
            >
            <v-tab class="share-tab-item" @click="reloadComponent(3)"
              >Embed</v-tab
            >
          </v-tabs>
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog" size="18" class="white--text">
            mdi-close
          </v-icon>
        </v-toolbar>
        <v-card-text class="pt-6 text-left">
          <v-tabs-items v-model="tab" class="tabs-content">
            <v-tab-item :key="reloadKey + '1'" class="tab-item">
              <v-card :elevation="0" class="share-container">
                <!-- folder toolbar -->
                <v-form @submit.prevent="shareLinkForm">
                  <div class="mb-3">Share to people through email</div>
                  <v-row>
                    <v-col cols="9" sm="9" md="9">
                      <v-text-field
                        v-model="email_addresses"
                        class="email-addresses-field"
                        label="Enter email address."
                        title="Separate multiple email by comma!"
                        :rules="[rules.required, rules.email]"
                        @blur="fieldBlurred = true"
                        required
                        solo
                        dense
                        flat
                        outlined
                        rounded
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" sm="3" md="3">
                      <v-btn
                        class="black--text capitalize px-4 share-btn"
                        :disabled="!isFormValid"
                        :loading="loading"
                        type="submit"
                        rounded
                        outlined
                        text
                      >
                        Share
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>

            <v-tab-item :key="reloadKey + '2'">
              <v-card :elevation="0" class="social-container">
                <!-- ShareComponent -->
                <v-card
                  v-for="(media, index) in social_media_share"
                  :key="index"
                  :elevation="0"
                  class="mx-auto social-card"
                >
                  <v-row @click="shareLink(media.platform)">
                    <v-col cols="2" class="social-col-icon media-col">
                      <v-icon
                        color="primary--text"
                        class="black--text left-social-icon"
                      >
                        {{ media.icon }}
                      </v-icon>
                    </v-col>
                    <v-col cols="7" class="text-sm-left social-col-body">
                      <v-card-text class="text-13 black--text social-body">
                        <div class="social-title">{{ media.title }}</div>
                        <small class="social-description">{{
                          media.context
                        }}</small>
                      </v-card-text>
                    </v-col>
                    <v-col
                      cols="3"
                      class="text-right social-col-icon share-col"
                    >
                      <v-icon
                        color="primary--text"
                        class="black--text right-share-icon"
                      >
                        mdi-share
                      </v-icon>
                      Share
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>
            </v-tab-item>

            <v-tab-item :key="reloadKey + '3'">
              <v-card :elevation="0" flat>
                <div class="pa-6" ref="codeContainer" style="width: 100%">
                  <div class="text-center mb-5 text--black">
                    Paste this HTML code in your website
                  </div>
                  <label>
                    <textarea
                      name="textarea"
                      style="width: 100%; border: 1px solid #ddd"
                      rows="6"
                      ref="code"
                      v-model="computeIframe"
                      readonly
                    ></textarea>
                  </label>
                </div>
                <v-card-actions class="mb-3">
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    class="primary mb-5 capitalize rounded-xl"
                    @click="copyCode"
                  >
                    Copy code
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pt-4 pb-4">
          <v-btn
            @click="copyRecordingLink"
            class="black--text capitalize px-6 font-weight-bold bordered-blue"
            rounded
            outlined
            color="blue"
            medium
            text
          >
            <v-icon size="18" class="white--black mr-2"
              >mdi-link-variant</v-icon
            >
            Copy link
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snackbar
      :color="snackBarColor"
      :snackBarText="snackBarText"
      :snackbar="snackbar"
    />
  </div>
</template>

<script>
import ResponseHelper from "../../utils/ResponseHelper";
// import { mapActions, mapMutations } from "vuex";
export default {
  name: "ShareRecordingDialog",
  props: {
    type: String,
    default: null,
  },
  data() {
    return {
      item: {},
      email_addresses: "",
      fieldBlurred: false,
      rules: {
        required: (value) => {
          if (!value.trim() && this.fieldBlurred) {
            return "E-mail is required.";
          }
          return true;
        },
        email: (value) => {
          const emails = value.split(",");
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          for (const email of emails) {
            if (!pattern.test(email.trim())) {
              return "Invalid e-mail.";
            }
          }
          return true;
        },
      },
      snackbar: false,
      snackBarText: "",
      snackBarColor: "",
      social_media_share: [
        {
          id: 1,
          icon: "mdi-linkedin",
          title: "Share on LinkedIn",
          context: "Embed this recording in a post",
          platform: "linkedin",
        },
        {
          id: 2,
          icon: "mdi-twitter",
          title: "Share on Twitter",
          context: "Embed this recording in a tweet",
          platform: "twitter",
        },
        {
          id: 3,
          icon: "mdi-facebook",
          title: "Share on Facebook",
          context: "Embed this recording in a post",
          platform: "facebook",
        },
        {
          id: 4,
          icon: "mdi-gmail",
          title: "Share in Gmail",
          context: "Embed this recording in a message",
          platform: "gmail",
        },
      ],
      share_recording_dialog: false,
      loading: false,
      toast: new ResponseHelper(),
      tab: null,
      reloadKey: 0,
    };
  },
  computed: {
    isFormValid() {
      return (
        this.rules.required(this.email_addresses) === true &&
        this.rules.email(this.email_addresses) === true
      );
    },
    computeIframe() {
      return (
        '<iframe width="853" height="480" src="' +
        this.item.video_url +
        '" title="' +
        this.item.title +
        '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
      );
    },
  },
  methods: {
    openDialog(item) {
      this.item = Object.assign({}, item);
      this.share_recording_dialog = true;
    },
    closeDialog() {
      this.share_recording_dialog = false;
    },
    copyCode() {
      const element = this.$refs.code;
      element.select();
      document.execCommand("copy");
      this.snackBarText = "Source code copied";
      this.snackbar = !this.snackbar;
      this.snackBarColor = "primary";
      setTimeout(() => {
        this.snackbar = false;
        window.getSelection().removeAllRanges();
      }, 2000);
    },
    async shareLinkForm() {
      this.loading = true;
      const shareLinkURI = this.sharableRecordingLink();
      try {
        const response = await this.$axios.post(
          "/recordings/share-recording-link",
          {
            emails: this.email_addresses,
            link: shareLinkURI,
          }
        );
        this.loading = false;
        this.toast.sendSuccess(response);
        this.email_addresses = "";
        this.closeDialog();
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
    copyRecordingLink() {
      const link = this.sharableRecordingLink();
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.snackBarText = "Recording link copied";
          this.snackbar = !this.snackbar;
          this.snackBarColor = "primary";
          console.log("Link copied to clipboard!");
        })
        .catch((error) => {
          this.snackBarText = "Failed to copy link to clipboard, try again!";
          this.snackbar = !this.snackbar;
          this.snackBarColor = "error";
          console.error("Failed to copy link to clipboard", error);
        });
    },
    sharableRecordingLink() {
      let link = "";
      let appStage = process.env.NODE_ENV;
      let protocol = appStage === "development" ? "http://" : "https://";
      link = protocol + this.item.domain + "/share/" + this.item.id;
      return link;
    },
    shareLink(platform) {
      const link = this.sharableRecordingLink();
      let shareUrl = "";
      switch (platform) {
        case "linkedin":
          shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${link}&sid=5adfb068-ba3e-4e62-9c23-eec927f62c12`;
          break;
        case "twitter":
          shareUrl = `https://twitter.com/intent/tweet?url=${link}`;
          break;
        case "facebook":
          shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${link}`;
          break;
        case "gmail":
          shareUrl = `mailto:?body=${link}`;
          break;
        default:
          // Handle other platforms or provide a default action
          break;
      }
      if (shareUrl) {
        window.open(shareUrl, "_blank");
      }
    },
    reloadComponent(tabIndex) {
      this.reloadKey += 1; // Increment the reload key
      this.tab = tabIndex; // Change the active tab to trigger a re-render
    },
  },
};
</script>

<style scoped>
.share-tab-toolbar {
  height: 35px !important;
  padding-left: 12px !important;
}
.share-tab-item {
  color: #fff !important;
  font-weight: 700;
  border-radius: 15px;
  padding: 10px;
  margin: 5px;
  height: 25px;
  font-size: 14px !important;
  text-transform: capitalize !important;
  cursor: pointer;
}
.v-tab--active {
  background-color: transparent;
  border: 1px solid #fff;
}
/* Share styles */
.share-btn {
  height: 40px !important;
}
/* Social styles */
.social-card {
  border: 1px solid #ddd;
  border-radius: 16px !important;
  height: 75px !important;
  margin-bottom: 15px;
}
.social-card:hover {
  transition: 0.7s;
  background-color: #e9e9fd !important;
  cursor: pointer;
}
.social-col-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.social-col-body {
  padding-left: 0px;
}
.left-social-icon {
  font-size: 23px !important;
  border-radius: 50% !important;
  padding: 6px;
  color: #625df5 !important;
  background-color: #e9e9fd !important;
}
.media-col {
  padding-right: 0px !important;
}
.share-col {
  padding-right: 8px !important;
  font-weight: 800;
}
.social-body > .social-title {
  font-weight: 800;
}
.social-body > .social-description {
  font-weight: 600;
  color: grey !important;
}
/* .social-body {} */
/* .right-share-icon {} */
/* Embed styles */
.embed-container {
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: grey;
}
.social-container {
  min-height: 150px;
}
.share-container {
  min-height: 150px;
}
.bordered-blue {
  border: 1px solid rgba(0, 0, 255, 0.295) !important;
}
/* .v-text-field__slot {
  border: 2px solid red !important;
} */
</style>
